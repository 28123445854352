/**
 * Popup (dialog block)
 *
 * =============================================================================
 * @example Bind invoke popup to DOM element (by click)
 * ---
 * html:
 * <button type="button" class="js-popup" data-popup-target="login">Login</button>
 *
 * javascript:
 * const popup = new Popup( '.js-popup', {
 *  // optional
 *  activeClassName: 'is-open',
 *  closeBtn: '.js-popup-close',
 *  contentBox: '.popup__body',
 * } );
 *
 * =============================================================================
 * @example Show popup by script (if another popup is visible one will be hide)
 * ---
 * const eventPopupShowSuccess =
 *        new CustomEvent( 'popup.show', { detail: { name: 'success' } } );
 * document.dispatchEvent( eventPopupShowSuccess );
 *
 * =============================================================================
 * @example Hide popup by script
 * ---
 * const eventPopupHide = new CustomEvent( 'popup.hide' );
 * document.dispatchEvent( eventPopupHide );
 */

import '../vendor/polyfills/classList';
import '../vendor/polyfills/core-js.es6.symbol';
import '../vendor/polyfills/custom-event-polyfill';
import '../vendor/polyfills/es6-assign';
import '../vendor/polyfills/from';
import '../vendor/polyfills/includes';

const defaults = {
  activeClassName: 'is-open',
  closeBtn: '.js-popup-close',
  contentBox: '.popup__body',
};

// helpers
const $ = ( selector, context = document ) => context.querySelector( selector );
const $$ = ( selector, context = document ) => context.querySelectorAll( selector );

const $html = $( 'html' );

const INIT_METHOD = Symbol( '@@init method' );
const SHOW_METHOD = Symbol( '@@show method' );
const HIDE_METHOD = Symbol( '@@hide method' );
const CREATE_BOX = Symbol( '@@create popup container' );

const SHOW_EVENT = 'popup.show';
const HIDE_EVENT = 'popup.hide';
const KEY_ESC = 27;
const instances = [];

let $popupActive = null;
let $popupCloseBtn = null;
let $popupContentBox = null;

class Popup {
  /**
   * Constructor
   *
   * @constructor
   * @param {string} selector - css selector
   * @param {object} options - popup options [optional]
   * @example
   * const popup = new Popup( '.js-popup' );
   */
  constructor( selector, options = {} ) {
    // one instance for the css selector
    if ( !instances.includes( selector ) ) {
      instances.push( selector );
      this[ INIT_METHOD ]( selector, options );
    }
  }

  // ***************
  // PUBLIC METHODS
  // ***************

  // ***************
  // PRIVATE METHODS
  // ***************
  /**
   * Initialize the instance
   *
   * @param {string} selector - css selector
   * @param {object} options - popup options
   *
   * @return {null} - nothing
   */
  [ INIT_METHOD ]( selector, options ) {
    this.options = Object.assign( {}, defaults, options );

    this[ CREATE_BOX ]();

    this.hide = ( ev ) => this[ HIDE_METHOD ]( ev );
    this.show = ( ev ) => this[ SHOW_METHOD ]( ev );

    // buttons click
    [ ...$$( selector ) ].forEach( ( btn ) => {
      btn.addEventListener( 'click', this.show );
    } );

    // show popup on document custom event 'popup.show'
    document.addEventListener( SHOW_EVENT, this.show );
    // hide popup on document custom event 'popup.hide'
    document.addEventListener( HIDE_EVENT, this.hide );
  }

  [ CREATE_BOX ]() {
    const box = document.createElement( 'div' );

    box.id = `popup${ Date.now() }`;
    document.body.appendChild( box );

    this.box = box;
  }

  /**
   * show popup block
   *
   * @param {object} ev - Event()
   * @return {Popup} - Popup object
   */
  [ SHOW_METHOD ]( ev ) {
    let btn = null;
    let popupTarget = null;
    let $popup = null;
    let formSubject = null;

    ev.preventDefault();

    if ( ev.isTrusted === true ) {
      // if event dispatched by user
      btn = ev.currentTarget;
      popupTarget = btn.getAttribute( 'data-popup-target' );
      formSubject = btn.getAttribute( 'data-form-subject' );
    } else {
      // if event dispatched by script
      popupTarget = ev.detail.name;
    }

    // find DOM popup-element
    if ( popupTarget !== null ) {
      $popup = $( `[data-popup-name=${ popupTarget }]` );
    }

    if ( $popup !== null ) {
      // if visible another popup then hide it
      this.hide();

      $popupActive = $popup;
      $popup.classList.add( this.options.activeClassName );

      // close button
      $popupCloseBtn = $popup.querySelector( this.options.closeBtn );

      // close popup by click outside an popup content (on fade)
      $popupContentBox = $popup.querySelector( this.options.contentBox );
      $popup.addEventListener( 'click', this.hide );

      // close popup by press ESC button
      document.addEventListener( 'keyup', this.hide );

      // $html.style.maxWidth = (document.documentElement.clientWidth || document.body.clientWidth) + 'px';
      $html.style.overflow = 'hidden';

      // !!! No need for popup functionality
      // Set subject for an inside form
      if ( formSubject !== null ) {
        const subject = $popup.querySelector( 'input[name=_subject]' );

        if ( subject !== null ) { subject.value = formSubject; }
      }
    }

    return this;
  }

  /**
   * Hide popup block
   *
   * @param {object} ev - Event()
   * @return {Popup} - Popup object
   */
  [ HIDE_METHOD ]( ev ) {
    // if no visible popup then skip process
    if ( $popupActive === null ) { return this; }

    // if ESC button pressed
    if ( typeof ev !== 'undefined' && ev.type === 'keyup' ) {
      const code = ev.keyCode || ev.which;

      if ( code !== KEY_ESC ) { return this; }
    }

    if ( typeof ev !== 'undefined' && ev.isTrusted === true ) {
      // if clicked outside the popup content (on fade)
      // and if NOT clicked the popup close button
      const isInsideClick = $popupContentBox !== null && $popupContentBox.contains( ev.target );
      const isCloseBtnClick = $popupCloseBtn !== null && $popupCloseBtn.contains( ev.target );

      if ( isInsideClick === true && isCloseBtnClick !== true ) { return this; }
    }

    // remove document listeners
    document.removeEventListener( 'keyup', this.hide );

    // remove popup listeners
    $popupActive.removeEventListener( 'click', this.hide );

    // remove active class name
    $popupActive.classList.remove( this.options.activeClassName );

    // $html.style.maxWidth = 'none';
    $html.style.overflow = 'auto';

    $popupActive = null;
    $popupCloseBtn = null;
    $popupContentBox = null;

    return this;
  }
}

export default Popup;

