var vanillaTextMask = function( e ) {
  function r( n ) {
    if ( t[ n ] ) {
      return t[ n ].exports;
    }
    var o = t[ n ] = { exports: {}, id: n, loaded: !1 };
    return e[ n ].call( o.exports, o, o.exports, r ), o.loaded = !0, o.exports
  }

  var t = {};
  return r.m = e, r.c = t, r.p = "", r( 0 )
}( [ function( e, r, t ) {
  "use strict";
  function n( e ) {return e && e.__esModule ? e : { default: e }}

  function o( e ) {
    var r = e.inputElement,
      t = (0, u.default)( e ),
      n = function( e ) {
        var r = e.target.value;
        return t.update( r )
      };
    return r.addEventListener( "input", n ), t.update( r.value ), {
      textMaskInputElement: t,
      destroy: function() {r.removeEventListener( "input", n )}
    }
  }

  Object.defineProperty( r, "__esModule", { value: !0 } ), r.conformToMask = void 0, r.maskInput = o;
  var i = t( 2 );
  Object.defineProperty( r, "conformToMask", {
    enumerable: !0,
    get: function() {return n( i ).default}
  } );
  var a = t( 5 ),
    u = n( a );
  r.default = o
}, function( e, r ) {
  "use strict";
  Object.defineProperty( r, "__esModule", { value: !0 } );
  r.placeholderChar = "_"
}, function( e, r, t ) {
  "use strict";
  function n() {
    var e = arguments.length > 0 && void 0 !== arguments[ 0 ] ? arguments[ 0 ] : a,
      r = arguments.length > 1 && void 0 !== arguments[ 1 ] ? arguments[ 1 ] : a,
      t = arguments.length > 2 && void 0 !== arguments[ 2 ] ? arguments[ 2 ] : {},
      n = t.guide,
      u = void 0 === n || n,
      l = t.previousConformedValue,
      s = void 0 === l ? a : l,
      d = t.placeholderChar,
      f = void 0 === d ? i.placeholderChar : d,
      c = t.placeholder,
      v = void 0 === c ? (0, o.convertMaskToPlaceholder)( r, f ) : c,
      p = t.currentCaretPosition,
      h = t.keepCharPositions,
      m = u === !1 && void 0 !== s,
      g = e.length,
      y = s.length,
      C = v.length,
      b = r.length,
      P = g - y,
      k = P > 0,
      x = p + (k ? -P : 0),
      O = x + Math.abs( P );
    if ( h === !0 && !k ) {
      for ( var T = a,
              M = x; M < O; M++ ) {
        v[ M ] === f && (T += f);
      }
      e = e.slice( 0, x ) + T + e.slice( x, g )
    }
    for ( var _ = e.split( a ).map( function( e, r ) {
        return {
          char: e,
          isNew: r >= x && r < O
        }
      } ),
            w = g - 1; w >= 0; w-- ) {
      var S = _[ w ].char;
      if ( S !== f ) {
        var V = w >= x && y === b;
        S === v[ V ? w - P : w ] && _.splice( w, 1 )
      }
    }
    var j = a,
      N = !1;
    e:for ( var E = 0; E < C; E++ ) {
      var A = v[ E ];
      if ( A === f ) {
        if ( _.length > 0 ) {
          for ( ; _.length > 0; ) {
            var I = _.shift(),
              L = I.char,
              R = I.isNew;
            if ( L === f && m !== !0 ) {
              j += f;
              continue e
            }
            if ( r[ E ].test( L ) ) {
              if ( h === !0 && R !== !1 && s !== a && u !== !1 && k ) {
                for ( var J = _.length,
                        q = null,
                        F = 0; F < J; F++ ) {
                  var W = _[ F ];
                  if ( W.char !== f && W.isNew === !1 ) {
                    break;
                  }
                  if ( W.char === f ) {
                    q = F;
                    break
                  }
                }
                null !== q ? (j += L, _.splice( q, 1 )) : E--
              } else {
                j += L;
              }
              continue e
            }
            N = !0
          }
        }
        m === !1 && (j += v.substr( E, C ));
        break
      }
      j += A
    }
    if ( m && k === !1 ) {
      for ( var z = null,
              B = 0; B < j.length; B++ ) {
        v[ B ] === f && (z = B);
      }
      j = null !== z ? j.substr( 0, z + 1 ) : a
    }
    return { conformedValue: j, meta: { someCharsRejected: N } }
  }

  Object.defineProperty( r, "__esModule", { value: !0 } ), r.default = n;
  var o = t( 3 ),
    i = t( 1 ),
    a = ""
}, function( e, r, t ) {
  "use strict";
  function n() {
    var e = arguments.length > 0 && void 0 !== arguments[ 0 ] ? arguments[ 0 ] : l,
      r = arguments.length > 1 && void 0 !== arguments[ 1 ] ? arguments[ 1 ] : u.placeholderChar;
    if ( e.indexOf( r ) !== -1 ) {
      throw new Error( "Placeholder character must not be used as part of the mask. Please specify a character that is not present in your mask as your placeholder character.\n\n" + ("The placeholder character that was received is: " + JSON.stringify( r ) + "\n\n") + ("The mask that was received is: " + JSON.stringify( e )) );
    }
    return e.map( function( e ) {return e instanceof RegExp ? r : e} ).join( "" )
  }

  function o( e ) {return "string" == typeof e || e instanceof String}

  function i( e ) {return "number" == typeof e && void 0 === e.length && !isNaN( e )}

  function a( e ) {
    for ( var r = [],
            t = void 0; t = e.indexOf( s ), t !== -1; ) {
      r.push( t ), e.splice( t, 1 );
    }
    return { maskWithoutCaretTraps: e, indexes: r }
  }

  Object.defineProperty( r, "__esModule", { value: !0 } ), r.convertMaskToPlaceholder = n, r.isString = o, r.isNumber = i, r.processCaretTraps = a;
  var u = t( 1 ),
    l = [],
    s = "[]"
}, function( e, r ) {
  "use strict";
  function t( e ) {
    var r = e.previousConformedValue,
      t = void 0 === r ? o : r,
      i = e.previousPlaceholder,
      a = void 0 === i ? o : i,
      u = e.currentCaretPosition,
      l = void 0 === u ? 0 : u,
      s = e.conformedValue,
      d = e.rawValue,
      f = e.placeholderChar,
      c = e.placeholder,
      v = e.indexesOfPipedChars,
      p = void 0 === v ? n : v,
      h = e.caretTrapIndexes,
      m = void 0 === h ? n : h;
    if ( 0 === l ) {
      return 0;
    }
    var g = d.length,
      y = t.length,
      C = c.length,
      b = s.length,
      P = g - y,
      k = P > 0,
      x = 0 === y,
      O = P > 1 && !k && !x;
    if ( O ) {
      return l;
    }
    var T = k && (t === s || s === c),
      M = 0,
      _ = void 0;
    if ( T ) {
      M = l - P;
    } else {
      var w = s.toLowerCase(),
        S = d.toLowerCase(),
        V = S.substr( 0, l ).split( o ),
        j = V.filter( function( e ) {return w.indexOf( e ) !== -1} ),
        N = j[ j.length - 1 ];
      _ = void 0 !== a[ j.length - 1 ] && void 0 !== c[ j.length - 2 ] && a[ j.length - 1 ] !== f && a[ j.length - 1 ] !== c[ j.length - 1 ] && a[ j.length - 1 ] === c[ j.length - 2 ];
      for ( var E = p.map( function( e ) {return w[ e ]} ),
              A = E.filter( function( e ) {return e === N} ).length,
              I = j.filter( function( e ) {return e === N} ).length,
              L = c.substr( 0, c.indexOf( f ) ).split( o ).filter( function( e, r ) {return e === N && d[ r ] !== e} ).length,
              R = L + I + A,
              J = 0,
              q = 0; q < b; q++ ) {
        var F = w[ q ];
        if ( M = q + 1, F === N && J++, J >= R ) {
          break
        }
      }
    }
    if ( k ) {
      for ( var W = M,
              z = M; z <= C; z++ ) {
        if ( c[ z ] === f && (W = z), c[ z ] === f || m.indexOf( z ) !== -1 || z === C ) {
          return W
        }
      }
    } else {
      for ( var B = M + (_ ? 1 : 0); B >= 0; B-- ) {
        if ( c[ B - 1 ] === f || m.indexOf( B ) !== -1 || 0 === B ) {
          return B
        }
      }
    }
  }

  Object.defineProperty( r, "__esModule", { value: !0 } ), r.default = t;
  var n = [],
    o = ""
}, function( e, r, t ) {
  "use strict";
  function n( e ) {return e && e.__esModule ? e : { default: e }}

  function o( e ) {
    var r = {
      previousConformedValue: void 0,
      previousPlaceholder: void 0
    };
    return {
      state: r,
      update: function( t ) {
        var n = arguments.length > 1 && void 0 !== arguments[ 1 ] ? arguments[ 1 ] : e,
          o = n.inputElement,
          s = n.mask,
          f = n.guide,
          g = n.pipe,
          C = n.placeholderChar,
          b = void 0 === C ? p.placeholderChar : C,
          P = n.keepCharPositions,
          k = void 0 !== P && P;
        if ( "undefined" == typeof t && (t = o.value), t !== r.previousConformedValue ) {
          ("undefined" == typeof s ? "undefined" : l( s )) === y && void 0 !== s.pipe && void 0 !== s.mask && (g = s.pipe, s = s.mask);
          var x = void 0,
            O = void 0;
          if ( s instanceof Array && (x = (0, v.convertMaskToPlaceholder)( s, b )), s !== !1 ) {
            var T = a( t ),
              M = o.selectionStart,
              _ = r.previousConformedValue,
              w = r.previousPlaceholder,
              S = void 0;
            if ( ("undefined" == typeof s ? "undefined" : l( s )) === h ) {
              if ( O = s( T, {
                  currentCaretPosition: M,
                  previousConformedValue: _,
                  placeholderChar: b
                } ), O === !1 ) {
                return;
              }
              var V = (0, v.processCaretTraps)( O ),
                j = V.maskWithoutCaretTraps,
                N = V.indexes;
              O = j, S = N, x = (0, v.convertMaskToPlaceholder)( O, b )
            } else {
              O = s;
            }
            var E = {
                previousConformedValue: _,
                guide: f,
                placeholderChar: b,
                pipe: g,
                placeholder: x,
                currentCaretPosition: M,
                keepCharPositions: k
              },
              A = (0, c.default)( T, O, E ),
              I = A.conformedValue,
              L = ("undefined" == typeof g ? "undefined" : l( g )) === h,
              R = {};
            L && (R = g( I, u( { rawValue: T }, E ) ), R === !1 ? R = {
              value: _,
              rejected: !0
            } : (0, v.isString)( R ) && (R = { value: R }));
            var J = L ? R.value : I,
              q = (0, d.default)( {
                previousConformedValue: _,
                previousPlaceholder: w,
                conformedValue: J,
                placeholder: x,
                rawValue: T,
                currentCaretPosition: M,
                placeholderChar: b,
                indexesOfPipedChars: R.indexesOfPipedChars,
                caretTrapIndexes: S
              } ),
              F = J === x && 0 === q,
              W = F ? m : J;
            r.previousConformedValue = W, r.previousPlaceholder = x, o.value !== W && (o.value = W, i( o, q ))
          }
        }
      }
    }
  }

  function i( e, r ) {document.activeElement === e && (C ? b( function() {return e.setSelectionRange( r, r, g )}, 0 ) : e.setSelectionRange( r, r, g ))}

  function a( e ) {
    if ( (0, v.isString)( e ) ) {
      return e;
    }
    if ( (0, v.isNumber)( e ) ) {
      return String( e );
    }
    if ( void 0 === e || null === e ) {
      return m;
    }
    throw new Error( "The 'value' provided to Text Mask needs to be a string or a number. The value received was:\n\n " + JSON.stringify( e ) )
  }

  Object.defineProperty( r, "__esModule", { value: !0 } );
  var u = Object.assign || function( e ) {
        for ( var r = 1; r < arguments.length; r++ ) {
          var t = arguments[ r ];
          for ( var n in t ) {
            Object.prototype.hasOwnProperty.call( t, n ) && (e[ n ] = t[ n ])
          }
        }
        return e
      },
    l = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function( e ) {return typeof e} : function( e ) {return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e};
  r.default = o;
  var s = t( 4 ),
    d = n( s ),
    f = t( 2 ),
    c = n( f ),
    v = t( 3 ),
    p = t( 1 ),
    h = "function",
    m = "",
    g = "none",
    y = "object",
    C = "undefined" != typeof navigator && /Android/i.test( navigator.userAgent ),
    b = "undefined" != typeof requestAnimationFrame ? requestAnimationFrame : setTimeout
} ] );

export default vanillaTextMask;
