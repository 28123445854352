/**
 * toggle active class and some attributes
 * @type {NodeList}
 */
const elToggles = document.querySelectorAll( '.js-toggle' );

[ ...elToggles ].forEach( ( el ) => {
  el.addEventListener( 'click', toggle );
} );

function toggle( e ) {
  const { currentTarget: $btn } = e;
  const activeClassName = $btn.getAttribute( 'data-toggle-active-class' ) || 'is-active';
  const target = $btn.getAttribute( 'data-toggle-target' ) || null;
  const newText = $btn.getAttribute( 'data-toggle-text' ) || null;
  const newTitle = $btn.getAttribute( 'data-toggle-title' ) || null;
  const group = $btn.getAttribute( 'data-toggle-group' ) || null;
  const done = $btn.getAttribute( 'data-toggle-callback-name' ) || null;

  if ( newText ) {
    $btn.setAttribute( 'data-toggle-text', $btn.innerHTML );
    $btn.innerHTML = newText;
  }

  if ( newTitle ) {
    $btn.setAttribute( 'data-toggle-title', $btn.getAttribute( 'title' ) );
    $btn.title = newTitle;
  }

  if ( target === 'parent' ) {
    // target: parent node
    $btn.parentNode.classList.toggle( activeClassName );
  } else if ( target ) {
    // target: other nodes
    target.split( ',' ).forEach( ( targ ) => {
      const elTarget = document.querySelector( targ.trim() );

      if ( elTarget ) {
        let targ2 = elTarget;

        if ( targ === 'parent' ) {
          targ2 = elTarget.parentNode;
        } else if ( targ === 'self' ) {
          targ2 = $btn;
        }

        targ2.classList.toggle( activeClassName );
      }
    } );
  }

  // set active class for button
  $btn.classList.toggle( activeClassName );

  // show only 1 item if group
  if ( group && $btn.classList.contains( activeClassName ) ) {
    const filteredEls = document.querySelectorAll( `.js-toggle[data-toggle-group="${ group }"]` );

    [ ...filteredEls ].forEach( ( $el ) => {
      if ( $el !== $btn ) {
        $el.classList.remove( activeClassName );
      }
    } );
  }

  // callback
  if ( done ) {
    done();
  }
}

