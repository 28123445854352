import vanillaTextMask from '../vendor/vanillaTextMask';

const phoneMask = [
  '+', '7',
  ' ', '(', /[1-9]/, /\d/, /\d/, ')',
  ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/,
];
const CARET_POS = 4;
const $phones = document.querySelectorAll( 'input[type=tel]' );

[ ...$phones ].forEach( ( inputElement ) => {
  const options = {
    inputElement,
    mask: phoneMask,
    placeholderChar: '_',
  };
  let mask = vanillaTextMask.maskInput( options );
  // let placeholder;

  // inputElement.addEventListener( 'focus', () => {
  //   if ( !placeholder ) {
  //     placeholder = inputElement.placeholder;
  //   }
  //   inputElement.placeholder = '+7 (___) ___-____';
  // } );

  inputElement.addEventListener( 'click', () => {
    if ( inputElement.value === '' ) {
      inputElement.value = '+7 (___) ___-__-__';
      inputElement.setSelectionRange( CARET_POS, CARET_POS );
      inputElement.focus();
    }
  } );

  inputElement.addEventListener( 'blur', () => {
    // inputElement.placeholder = placeholder;

    if ( /_/.test( inputElement.value ) ) {
      inputElement.value = '';
      mask.destroy();
      mask = vanillaTextMask.maskInput( options );
    }
  } );
} );
