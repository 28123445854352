import '../vendor/polyfills/from';
import hasSupportWebp from '../vendor/supports-webp.browser';

const els = document.querySelectorAll( '[data-bg]:not(.lazyload)' );

[ ...els ].forEach( ( el ) => {
  const target = el.getAttribute( 'data-bg' );
  const src = el.getAttribute( 'data-src' ) || el.src || target;
  const ext = el.getAttribute( 'data-webp-alt' ) || 'jpg';
  const arr = src.split( ',' );
  let bgi = '';

  if ( target === 'parent' ) {
    el.setAttribute( 'hidden', true );
    el = el.parentNode;
  }

  // concatenation multiple backgrounds
  arr.forEach( ( src2, i ) => {
    if ( i > 0 ) {
      bgi += ',';
    }

    if ( !hasSupportWebp ) {
      src2 = src2.replace( /\.webp/g, '.' + ext );
    }

    bgi += `url(${src2.trim()})`;
  } );

  el.style.backgroundImage = bgi;
} );
