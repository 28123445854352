import '../vendor/lazysizes/ls.object-fit';
import '../vendor/lazysizes/ls.unveilhooks';
import '../vendor/lazysizes/lazysizes';
import hasSupportWebp from '../vendor/supports-webp.browser';

document.addEventListener( 'lazybeforeunveil', ( event ) => {
  const { target } = event;
  const altExt = hasSupportWebp ? null : ( target.getAttribute( 'data-webp-alt' ) || 'jpg' );
  let bg = target.getAttribute( 'data-bg' );
  let src = target.getAttribute( 'data-src' ) || target.src;
  
  target.classList.add( 'lazy' );

  if ( bg ) {
    if ( bg === 'parent' ) {
      if ( !hasSupportWebp ) {
        src = src.replace( '.webp', '.' + altExt );
      }

      target.setAttribute( 'hidden', true );
      target.parentNode.style.backgroundImage = `url(${ src })`;
    } else {
      if ( !hasSupportWebp ) {
        bg = bg.replace( /\.webp/g, '.' + altExt );
      }

      const arr = bg.split( ',' );
      let bgi = '';

      // multiple backgrounds
      arr.forEach( ( el, i ) => {
        if ( i > 0 ) {
          bgi += ',';
        }
        bgi += `url(${ el.trim() })`;
      } );

      target.style.backgroundImage = bgi;
    }
  } else {
    if ( !hasSupportWebp && target.nodeName === 'IMG' ) {
      target.setAttribute( 'data-src', src.replace( /\.webp/g, '.' + altExt ) );
    }
  }
} );
