const $buttons = document.querySelectorAll( '.js-slide' );
let height = 0;
let forceReflow = 0;

[ ...$buttons ].forEach( ( $btn ) => {
  const target = $btn.getAttribute( 'data-slide-target' );
  const $targets = document.querySelectorAll( target );
  let altText = $btn.getAttribute( 'data-slide-text' );

  // [ ...$targets ].forEach( ( $target ) => {
  //   $target.style.height = 0;
  //   $target.classList.remove( 'is-open' );
  // } );

  // $btn.classList.remove( 'is-open' );

  $btn.addEventListener( 'click', () => {
    [ ...$targets ].forEach( ( $target ) => {

      if ( $target.classList.contains( 'is-open' ) ) {
        height = $target.clientHeight;
        $target.style.height = `${ height }px`;
        forceReflow = $target.clientHeight;
        $target.style.height = 0;

      } else {
        $target.style.height = 'auto';

        height = $target.clientHeight;
        $target.style.height = 0;
        forceReflow = $target.clientHeight;
        $target.style.height = `${ height }px`;
      }

      $target.classList.toggle( 'is-open' );

      if ( altText ) {
        const altText2 = $btn.innerHTML;

        $btn.innerHTML = altText;
        altText = altText2;
      }

      if ( $target.classList.contains( 'is-open' ) ) {
        $btn.classList.add( 'is-open' );
      } else {
        $btn.classList.remove( 'is-open' );
      }
    } );
  } );
} );
