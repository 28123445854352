/* global flatpickr */

import svg4everybody from './vendor/polyfills/svg4everybody';
import './vendor/polyfills/picturefill';

import './vendor/flatpickr';
import './vendor/flatpickr_ru';

import './app/backgroundimage';
import './app/lazysizes';
import './app/lightgallery';
import './app/map';
import Popup from './app/popup';
import './app/portfolio';
import './app/slide';
import './app/swiper';
import './app/text-mask';
import './app/toggle';

svg4everybody();

const popup = new Popup( '.js-popup' );

/**
 * flatpickr
 */
// datepicker
{
  const $dates = document.querySelectorAll( '.js-date' );

  [ ...$dates ].forEach( ( $el ) => {
    flatpickr( $el, {
      locale: 'ru',
      dateFormat: 'd.m.Y',
      minDate: 'today',
      appendTo: $el.parentNode,
      static: true,
    } );
  } );
}

// timepicker
{
  const $dates = document.querySelectorAll( '.js-time' );

  [ ...$dates ].forEach( ( $el ) => {
    flatpickr( $el, {
      locale: 'ru',
      enableTime: true,
      noCalendar: true,
      time_24hr: true,
      dateFormat: 'H:i',
      minDate: '08:00',
      maxDate: '22:00',
      appendTo: $el.parentNode,
      static: true,
    } );
  } );
}

/**
 * form
 */
const $fields = document.querySelectorAll( '.form input, .form textarea' );

[ ...$fields ].forEach( ( $field ) => {
  $field.addEventListener( 'blur', checkField );
  checkField();

  /**
   * show/hide label name (placeholder)
   */
  function checkField() {
    $field.value = $field.value.trim();

    if ( $field.value === '' ) {
      $field.classList.remove( 'is-valid' );
    } else {
      $field.classList.add( 'is-valid' );
    }
  }
} );
