/* global ymaps */
import '../vendor/polyfills/intersection-observer';

const $map = document.getElementById( 'map' );
let observer = null;

if ( $map ) {
  observer = new IntersectionObserver( ( entries ) => {
    entries.forEach( ( entry ) => {
      if ( entry.isIntersecting ) {
        loadScript();
        observer.unobserve( entry.target );
      }
    } );
  }, {} );

  observer.observe( $map );
}

window.mapInit = () => {
  const zoom = $map.getAttribute( 'data-zoom' ) || 16; // eslint-disable-line no-magic-numbers
  const center = $map.getAttribute( 'data-center-coord' ).split( ',' );
  let placemarkCoord = $map.getAttribute( 'data-placemark-coord' );

  placemarkCoord = placemarkCoord ? placemarkCoord.split( ',' ) : center;

  const myMap = new ymaps.Map( 'map', {
    zoom,
    center,
    controls: [ 'rulerControl' ],
  } );

  myMap.controls.add( 'zoomControl', {
    position: {
      bottom: '50px',
      left: '10px',
    },
  } );

  const myPlacemark1 = new ymaps.Placemark( placemarkCoord, {
    hintContent: 'Е2-Автосервис',
  }, {
    iconLayout: 'default#image',
    iconImageHref: 'img/placeholder.png',
    iconImageSize: [ 55, 74 ], // eslint-disable-line no-magic-numbers
    iconImageOffset: [ -27, -74 ], // eslint-disable-line no-magic-numbers
  } );

  myMap.behaviors.disable( 'scrollZoom' );
  myMap.geoObjects.add( myPlacemark1 );
};

function loadScript() {
  const script = document.createElement( 'script' );

  script.type = 'text/javascript';
  script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&onload=mapInit';
  document.getElementsByTagName( 'head' )[ 0 ].appendChild( script );
}

