/* global Swiper*/
import '../vendor/polyfills/from';

/**
 * @example {NodeList}
 * <div class="js-slider"
 * data-swiper-prev="#prev-btn"
 * data-swiper-next="#next-btn"
 * data-swiper-perview="1,1,1,1"
 * data-swiper-between="0,10,5,1">
 *
 * data-swiper-***="1,1,1,1": [extra-large, small, medium, large]
 */

const elSliders = document.querySelectorAll( '.js-slider' );
const breakpointSmall = 540;
const breakpointMedium = 900;
const breakpointLarge = 1200;

if ( window.Swiper ) {
  [ ...elSliders ].forEach( ( el ) => {
    const autoplay = el.getAttribute( 'data-swiper-autoplay' );
    const speed = +el.getAttribute( 'data-swiper-speed' ) || 500;
    const prevButton = el.getAttribute( 'data-swiper-prev' );
    const nextButton = el.getAttribute( 'data-swiper-next' );
    const pagination = el.getAttribute( 'data-swiper-pagination' );
    const direction = el.getAttribute( 'data-swiper-direction' ) || 'horizontal';
    let loop = el.getAttribute( 'data-swiper-loop' );
    let slidesPerView = el.getAttribute( 'data-swiper-perview' ) || '1';
    let spaceBetween = el.getAttribute( 'data-swiper-between' ) || '0';
    let slidesPerGroup = el.getAttribute( 'data-swiper-group' ) || '1';

    loop = loop !== 'false';
    slidesPerView = slidesPerView.split( ',' );
    spaceBetween = spaceBetween.split( ',' );
    slidesPerGroup = slidesPerGroup.split( ',' );

    const slider = new Swiper( el, {
      autoplay,
      speed,
      prevButton,
      nextButton,
      loop,
      pagination,
      direction,
      autoHeight: true,
      slidesPerGroup: +slidesPerView[ 0 ],
      slidesPerView: +slidesPerView[ 0 ],
      spaceBetween: +spaceBetween[ 0 ],
      setWrapperSize: true,
      roundLengths: true,
      paginationClickable: true,
      breakpoints: {
        [ breakpointSmall ]: {
          slidesPerView: +slidesPerView[ 1 ] || 1,
          slidesPerGroup: +slidesPerGroup[ 1 ] || 1,
          spaceBetween: +spaceBetween[ 1 ] || 0,
        },
        [ breakpointMedium ]: {
          slidesPerView: +slidesPerView[ 2 ] || 1,
          slidesPerGroup: +slidesPerGroup[ 2 ] || 1,
          spaceBetween: +spaceBetween[ 2 ] || 0,
        },
        [ breakpointLarge ]: {
          slidesPerView: +slidesPerView[ 3 ] || 1,
          slidesPerGroup: +slidesPerGroup[ 3 ] || 1,
          spaceBetween: +spaceBetween[ 3 ] || 0,
        },
      },
    } );

    el.addEventListener( 'mouseenter', () => {
      slider.stopAutoplay();
    } );

    el.addEventListener( 'mouseleave', () => {
      slider.startAutoplay();
    } );
  } );
}

