const $btns = document.querySelectorAll( '.js-portfolio .portfolio__filter__btn' );
const $items = document.querySelectorAll( '.js-portfolio .media-link' );

[ ...$btns ].forEach( ( $btn ) => {
  $btn.addEventListener( 'click', toggle );
} );

function toggle( e ) {
  const $activeBtn = e.currentTarget;
  const activeCategory = $activeBtn.getAttribute( 'data-category-target' );

  // filter categories
  [ ...$items ].forEach( ( $item ) => {
    const category = $item.getAttribute( 'data-category-name' );

    if (!activeCategory || activeCategory === category ) {
      $item.classList.remove( 'is-disable' );
    } else {
      $item.classList.add( 'is-disable' );
    }
  } );

  // reset active filter buttons
  [ ...$btns ].forEach( ( $btn ) => {
    $btn.classList.remove( 'is-active' );
  } );

  $activeBtn.classList.add( 'is-active' );
}

